import { FC } from 'react'
import Image from "next/legacy/image"
import Link from 'next/link'

interface props {
  items: {
    collection_image: {
      dimensions: {
        width: number
        height: number
      }
      alt: string
      url: string
    }
    collection_link: string
    collection_title: string
  }[]
  primary: {
    background_colour: string
    padding_top: boolean
    section_subheading: string
    section_heading: string
    title_colour: string
  }
}

const CollectionRow: FC<props> = ({ primary, items }) => {
  const { section_subheading, section_heading, background_colour, title_colour, padding_top } = primary

  const colStart = 7 - items.length
  const colEnd = 12 - colStart + 1

  return (
    <section
      className={`pb-40 laptop:pb-80${padding_top ? ' pt-40 laptop:pt-80' : ''}`}
      style={{ backgroundColor: background_colour }}
    >
      <div className="wrapper">
        <div className="grid grid-cols-8 laptop:grid-cols-12 gap-12 laptop:gap-16">
          <div className="collection-row__header mb-32">
            {section_subheading && (
              <div className="title mb-8 text-moss" style={{ color: title_colour }}>
                {section_subheading}
              </div>
            )}
            {section_heading && <h5 className="heading-5">{section_heading}</h5>}
          </div>
        </div>
        <div className="grid grid-cols-8 laptop:grid-cols-12 gap-x-12 gap-y-24 laptop:gap-16">
          {items &&
            items.map((item, i) => {
              return (
                <Link key={i} href={item.collection_link} passHref legacyBehavior>
                  <a className="collection-card">
                    <div className="collection-card__image mb-12">
                      <Image
                        src={item.collection_image.url}
                        layout="fill"
                        objectFit="cover"
                        alt={item.collection_image.alt || 'Sheet Society'}
                        sizes={'(min-width: 1024px) 216px, (min-width: 768px) 474px, 162px'}
                      />
                    </div>
                    <div className="body-bold">{item.collection_title}</div>
                  </a>
                </Link>
              );
            })}
        </div>
      </div>
      <style jsx global>{`
        .collection-row__header {
          grid-column: span 8;
        }

        .collection-card {
          grid-column: span 4;
        }

        .collection-card:first-of-type {
          grid-column: span 4;
        }

        @media screen and (min-width: 1024px) {
          .collection-row__header {
            grid-column: ${colStart <= 1 ? 1 : colStart} / span ${colEnd};
          }

          .collection-card {
            grid-column: span 2;
          }

          .collection-card:first-of-type {
            grid-column: ${colStart <= 1 ? 1 : colStart} / span 2;
          }
        }

        .collection-card__image {
          position: relative;
          padding-top: 100%;
          width: 100%;
        }

        .collection-card__image img {
          width: 100%;
        }
      `}</style>
    </section>
  );
}

export default CollectionRow
